import React from "react"
import { ThemeProvider } from "styled-components"

import { lightTheme, darkTheme, Style } from "src/style"
import Menu from "src/components/Menu"
// import MetaTags from "src/components/MetaTags"

const themes = {
  dark: darkTheme,
  light: lightTheme,
}

export default ({ children, title, themeName, bgColor }) => {
  const defaultTheme = themeName ? themes[themeName] : lightTheme

  const theme = bgColor
    ? {
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
          background: bgColor,
        },
      }
    : defaultTheme

  return (
    <ThemeProvider theme={theme}>
      {/* <MetaTags /> */}
      <div>
        <Menu title={title} />
        <Style />
        {children}
      </div>
    </ThemeProvider>
  )
}
