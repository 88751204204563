import NeueHaasGroteskBoldEOT from "./8d290bc2-1f22-40ea-be12-7000a5406aff.eot"
import NeueHaasGroteskBoldWOFF2 from "./d13fb250-6b64-4d97-85df-51fc6625a891.woff2"
import NeueHaasGroteskBoldWOFF from "./60fa2ce6-c35e-4203-9bbf-25dd128daec5.woff"
import NeueHaasGroteskBoldTTF from "./dda121ff-e230-440f-83fb-40aefbd6e09a.ttf"
import NeueHaasGroteskRegularEOT from "./d24ae558-ac0f-4a43-96da-dd49b68947f5.eot?#iefix"
import NeueHaasGroteskRegularWOFF2 from "./a14594bf-73de-4b5f-9792-9566994a021d.woff2"
import NeueHaasGroteskRegularWOFF from "./bb4a10bb-155d-4c1a-a813-c65e10fac36c.woff"
import NeueHaasGroteskRegularTTF from "./53812a68-b352-4951-b19c-fe964db7ffe2.ttf"
import GTSuperDisplayLightEOT from "./GT-Super-Display-Light.eot"
import GTSuperDisplayLightWOFF2 from "./GT-Super-Display-Light.woff2"
import GTSuperDisplayLightWOFF from "./GT-Super-Display-Light.woff"
import GTSuperDisplayLightTTF from "./GT-Super-Display-Light.ttf"
import GTSuperDisplayLightOTF from "./GT-Super-Display-Light.otf"

export default {
NeueHaasGroteskBoldEOT,
NeueHaasGroteskBoldWOFF2,
NeueHaasGroteskBoldWOFF,
NeueHaasGroteskBoldTTF,
NeueHaasGroteskRegularEOT,
NeueHaasGroteskRegularWOFF2,
NeueHaasGroteskRegularWOFF,
NeueHaasGroteskRegularTTF,
GTSuperDisplayLightEOT,
GTSuperDisplayLightWOFF2,
GTSuperDisplayLightWOFF,
GTSuperDisplayLightTTF,
GTSuperDisplayLightOTF,
}
