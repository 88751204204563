import styled, { createGlobalStyle } from "styled-components"
import { Link } from "gatsby"
import {
  display,
  alignItems,
  justifyContent,
  flexDirection,
  flex,
  space,
  color,
  fontSize,
  fontFamily,
  width,
  maxWidth,
  fontWeight,
  lineHeight,
  position,
} from "styled-system"
import { animated } from "react-spring"

import fonts from "src/fonts/index"

const theme = {
  fonts: {
    serif: "'GT Super Display Light', serif",
    sans: "'Neue Hass Regular', sans-serif",
    boldSans: "'Neue Hass Bold', sans-serif",
  },
  fontSizes: {
    root: {
      desktop: "16px",
      mobile: "13px",
    },
    base: "1rem",
    lg: "1.25rem",
    md: "1rem",
    sm: "0.875rem",
    sx: "0.8125rem",
    xs: "0.75rem",
    xx: "0.625rem",
    xl: "3.5rem",
    xxl: "8rem",
    h1: "2.5rem",
    h2: "2rem",
    h3: "1.75rem",
    h4: "1.5rem",
    h5: "1.25rem",
    h6: "1rem",
    huge: "5rem",
  },
  space: [
    "0",
    "0.125em",
    "0.25em",
    "0.34375em",
    "0.65625em",
    "0.75em",
    "1em", // 6
    "2em",
    "3em",
    "4em",
    "8em",
    "16em",
    "24em",
    "32em",
    "64em",
  ],
  colors: {
    background: "#F2F2F2",
    black: "#000",
    gray: "#afafaf",
    dark_gray: "#7b7b7b",
    text: "#000",
    blue: "#00F",
    logo: "#000",
  },
  breakpoints: ["768px", "1025px", "1500px", "2200px"],
  mediaQueries: {
    portrait: `@media (max-aspect-ratio: 1/1)`,
    small: `@media (min-width: 768px)`,
    medium: `@media (min-width: 1025px)`,
    large: `@media (min-width: 1500px)`,
    fifteeninches: `@media (min-width: 1680px)`,
    xlarge: `@media (min-width: 2200px)`,
  },
}

const lightTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    text: "#000",
    black: "#000",
    blue: "#00F",
  },
}

const darkTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    text: "#fff",
    background: "#030303",
    logo: "#fff",
  },
}

const Style = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @font-face {
    font-family: 'Neue Hass Bold';
    font-style: normal;
    font-weight: normal;
    src:url('${fonts.NeueHaasGroteskBoldEOT}');
    src:url('${fonts.NeueHaasGroteskBoldEOT}') format("eot"),url('${
  fonts.NeueHaasGroteskBoldWOFF2
}') format("woff2"),url('${
  fonts.NeueHaasGroteskBoldWOFF
}') format("woff"),url('${fonts.NeueHaasGroteskBoldTTF}') format("truetype");
  }
  @font-face {
    font-family: 'Neue Hass Regular';
    font-style: normal;
    font-weight: normal;
    src:url('${fonts.NeueHaasGroteskRegularEOT}');
    src:url('${fonts.NeueHaasGroteskRegularEOT}') format("eot"),url('${
  fonts.NeueHaasGroteskRegularWOFF2
}') format("woff2"),url('${
  fonts.NeueHaasGroteskRegularWOFF
}') format("woff"),url('${fonts.NeueHaasGroteskRegularTTF}') format("truetype");
  }
  @font-face {
    font-family: 'GT Super Display Light';
    font-style: normal;
    font-weight: normal;
    src:url('${fonts.GTSuperDisplayLightEOT}');
    src:url('${fonts.GTSuperDisplayLightEOT}') format("eot"),url('${
  fonts.GTSuperDisplayLightWOFF2
}') format("woff2"),url('${
  fonts.GTSuperDisplayLightWOFF
}') format("woff"),url('${
  fonts.GTSuperDisplayLightTTF
}') format("truetype"),url('${
  fonts.GTSuperDisplayLightOTF
}') format('opentype');

  }
  ::-moz-selection { background: #000; color: #fff; }
  ::selection { background: #000; color: #fff; }
  html {
    font-family: 'Neue Hass Regular';
    font-size: ${theme.fontSizes.root.mobile};

    ${theme.mediaQueries.medium} {
      font-size: ${theme.fontSizes.root.desktop};
    }

    ${theme.mediaQueries.large} {
      font-size: 0.98vw;
    }
  }
  body {
    background-color: ${props =>
      props.theme !== undefined ? props.theme.colors.background : "white"};
    transition: background-color 200ms linear;
    margin: 0;
  }
`
const Box = styled(animated.div)`
  ${space}
  ${width}
  ${fontSize}
  ${color}
  ${flex}
  ${display}
  ${justifyContent}
  ${alignItems}
  ${flexDirection}
  ${maxWidth}
  ${position}
`
Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...flex.propTypes,
  ...display.propTypes,
  ...justifyContent.propTypes,
  ...alignItems.propTypes,
  ...flexDirection.propTypes,
}

const Text = styled(animated.div)`
  color: ${props =>
    props.theme.colors.text !== undefined
      ? props.theme.colors.text
      : "inherit"};
  ${space}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${color}
  ${fontFamily}
  ${width}

  a {
    text-decoration: none;
    color: ${props =>
      props.theme.colors.text !== undefined
        ? props.theme.colors.text
        : "inherit"};
  }
`
Text.propTypes = {
  ...space.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...lineHeight.propTypes,
  ...color.propTypes,
}

const Heading = Text.withComponent("h1")

Heading.defaultProps = {
  fontSize: 4,
  lineHeight: 1.5,
  m: 0,
}

const Subheading = Text.withComponent("h2")

Subheading.defaultProps = {
  fontSize: 2,
  lineHeight: 1.5,
  m: 0,
}

const A = styled(Link)`
  text-decoration: none;
  color: inherit;
  backface-visibility: hidden;
  tap-highlight-color: transparent;
  transform-style: preserve-3d;
  transform: translateZ(0);

  &:hover,
  &:focus,
  &:hover > *,
  &:hover > * > * {
    color: rgb(175, 175, 175) !important;
  }
`

const Year = styled(Text).attrs({
  fontFamily: "sans",
  fontSize: "xx",
  py: 1,
  px: 4,
  color: "dark_gray",
})`
  display: inline-block;
  vertical-align: top;
  line-height: 1.4em;
  margin-top: 0.15em;
  padding-left: 0.5em;
  padding-right: 0em;
  padding-bottom: 0.18em;
  padding-top: 0em;
  text-align: right;
  border: 0px solid rgba(216, 216, 216, 0.25);
  border-radius: 1em;
`

const Img = styled.img`
  max-width: 100%;
  transform: translate3d(0,0,0);
`

const Caption = styled(Text).attrs({
  fontSize: "sx",
  fontFamily: "sans",
  align: "left",
  my: "0.4em",
})`
  min-height: ${props => props.theme.space[9]};
  letter-spacing: 0.015em;
  width: 100%;
  text-align: justify;

  ${theme.mediaQueries.small} {
    max-width: 75%;
    font-size: 0.8rem;
  }

  ${theme.mediaQueries.medium} {
    font-size: 0.8rem;
  }

  ${theme.mediaQueries.large} {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.75em;
    text-align: center;
    font-size: 0.75rem;
  }
  ${theme.mediaQueries.xlarge} {
    max-width: 1000px;
    font-size: 0.65rem;
  }
`

const P = styled(Text).attrs({
  fontSize: "lg",
  align: "left",
  fontFamily: "sans",
})`
  line-height: 1.45;
  letter-spacing: 0.015em;
  transform: translate3d(0,0,0);
  backface-visibility: hidden;

  ${theme.mediaQueries.large} {
    font-size: 1.3rem;
  }

  ${theme.mediaQueries.fifteeninches} {
    font-size: 1.15rem;
  }

  ${theme.mediaQueries.xlarge} {
    font-size: 1rem;
  }
`

const Iframe = styled.iframe.attrs({
  frameborder: "0",
  webkitallowfullscreen: true,
  mozallowfullscreen: true,
  allowfullscreen: true,
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

export {
  Heading,
  Subheading,
  Text,
  Box,
  theme,
  lightTheme,
  darkTheme,
  Style,
  A,
  Year,
  Img,
  Caption,
  P,
  Iframe,
}
