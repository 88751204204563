import React from "react"
import { Helmet } from "react-helmet"

const MetaTitle = ({ title }) => {
  const titleTemplate = title ? `${title} – MP Creative` : `MP Creative`

  return <Helmet title={titleTemplate} />
}

export default MetaTitle
