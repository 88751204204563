import React from "react"

import DesktopMenu from "src/components/Menu/Desktop"
import MobileMenu from "src/components/Menu/Mobile"

export default ({ title }) => {
  return (
    <>
      <DesktopMenu title={title} />
      <MobileMenu title={title} />
    </>
  )
}
