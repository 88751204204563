import React, { useEffect, useMemo } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useWindowScroll } from "react-use"
import { useSpring } from "react-spring"
import { animateScroll } from "react-scroll"

import { globalHistory } from "@reach/router"

import { Box, theme } from "src/style"
import Icon from "src/components/Icon"

const DesktopContainer = styled(Box)`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  padding-top: ${props => props.top};
  padding-left: calc((100vw - 1100px) / 2);
  font-family: ${props => props.theme.fonts.sans};
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: 1.35;
  flex-direction: row;
  min-height: 100px;
  display: none;
  transform: translateZ(0);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  perspective: 1000;

  ${theme.mediaQueries.small} {
    display: flex;
    padding-left: 5%;
  }

  ${theme.mediaQueries.medium} {
    padding-left: 10%;
  }

  ${theme.mediaQueries.large} {
    padding-left: calc((100vw - 1300px) / 2);
  }

  ${theme.mediaQueries.xlarge} {
    padding-left: calc((100vw - 1500px) / 2);
  }
`

const A = styled(Link)`
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  perspective: 1000;
  text-decoration: none;
  color: ${props => props.theme.colors.text};
  line-height: 1.5;
  font-size: 0.95rem;
  letter-spacing: 0.015em;

  &:hover,
  &:focus {
    color: rgb(175, 175, 175);
  }

  ${theme.mediaQueries.large} {
    font-size: 1rem;
  }
`

const Menu = styled(Box)``

const Hamburger = styled(Box)`
  width: 38px;
  height: 35px;
  position: absolute;
  top: 0;
  left: calc((100vw - 1100px) / 2 + 17em);
  cursor: pointer;
  z-index: 200;
  transform: translateZ(20px);

  ${theme.mediaQueries.small} {
    left: calc(5% + 17em);
  }

  ${theme.mediaQueries.medium} {
    left: calc(10% + 17em);
  }

  ${theme.mediaQueries.large} {
    left: calc((100vw - 1300px) / 2 + 17em);
  }

  ${theme.mediaQueries.xlarge} {
    left: calc((100vw - 1500px) / 2 + 17em);
  }
`

const Line = styled(Box)`
  width: 100%;
  height: 2px;
  margin: 4px 0;
  background-color: ${props => props.theme.colors.text};
`

const MenuItem = styled(A).attrs({ activeClassName: "active" })`
  display: block;

  &:before {
    content: "•";
    opacity: 0;
    padding-right: 0.5em;
  }

  &.active {
    color: rgb(175, 175, 175);
    cursor: default;
  }

  &.active:hover {
    color: rgb(175, 175, 175);
    cursor: default;
  }

  &.active:before {
    opacity: 1;
  }

  ${props =>
    props.isActive &&
    `
    
    &:before {
      opacity: 1;
    }
  `}
`

const Submenu = styled(Box)`
  display: flex;
  flex-direction: row;
`

const Comma = styled(Icon).attrs({ name: "Comma" })`
  margin-left: 1em;
  margin-top: 0.125em;
`

const Sublink = styled(A).attrs({ activeClassName: "active" })`
  padding: 0 0 0 1.5em;

  &:first-of-type {
    padding-left: 0.25em;
  }

  &.active {
    color: ${props => props.theme.colors.gray};
  }
`

const query = graphql`
  query FeaturedWorkQuery {
    homepage: prismicHomepage(slugs: { eq: "homepage" }) {
      data {
        projects {
          linked_project {
            id
            uid
          }
        }
      }
    }
  }
`

export default ({ title }) => {
  const data = useStaticQuery(query)
  const uids =
    data &&
    data.homepage &&
    data.homepage.data &&
    data.homepage.data.projects &&
    data.homepage.data.projects.map(
      p => p && p.linked_project && p.linked_project.uid
    )
  //
  // Getting current route
  //
  const {
    location: { pathname },
  } = globalHistory
  const routes = pathname.split("/").filter(Boolean)
  const isWorkRoute = routes && routes[0] === "work"
  const isFeatured = isWorkRoute && uids.includes(routes[1])
  const isArchived = isWorkRoute && !isFeatured
  const isJustWork = isWorkRoute && routes.length === 1
  const isSubPage =
    isWorkRoute &&
    routes[1] &&
    (routes[1] === "clients-index" ||
      routes[1] === "motion" ||
      routes[1] === "case-studies" ||
      routes[1] === "campaigns" ||
      routes[1] === "editorial")
  const isProjectPage = isWorkRoute && !isJustWork
  //
  // Keeping track of scroll state
  //
  const { y } = useWindowScroll()
  const belowThreshold = useMemo(() => {
    return y > 40
  }, [y])

  const { top, menuOpacity, hamburgerOpacity, hamburgerPointer } = useSpring({
    top: belowThreshold ? 10 : 50,
    menuOpacity: belowThreshold ? 0 : 1,
    hamburgerOpacity: belowThreshold ? 1 : 0,
    hamburgerPointer: belowThreshold ? "all" : "none",
    config: {
      duration: 200,
    },
  })

  //
  // Go ahead and fire an event to reset menu
  //
  useEffect(() => {
    const event = new Event("scroll")
    window.dispatchEvent(event)
  }, [pathname])

  return (
    <>
      <DesktopContainer style={{ marginTop: top }}>
        <Box width="16em">
          <A to="/">
            <Icon name="Logo" color="logo" width="8em" height="3em" />
          </A>
        </Box>
        <Menu style={{ opacity: menuOpacity }}>
          <MenuItem to="/" isActive={isFeatured}>
            Featured Work
          </MenuItem>

          {/* Featured project sub-menu */}
          {isProjectPage && title && isFeatured && (
            <Submenu>
              <Comma color="logo" />
              <Sublink to={pathname}>{title}</Sublink>
            </Submenu>
          )}

          <MenuItem to="/work" isActive={isArchived}>
            Archive
          </MenuItem>

          {/* Project sub-menu */}
          {isProjectPage && title && isArchived && (
            <Submenu>
              <Comma color="logo" />
              <Sublink to={pathname}>{title}</Sublink>
            </Submenu>
          )}

          {/* Work sub-memu */}
          {(isJustWork || isSubPage) && (
            <Submenu>
              <Comma color="logo" />
              <Sublink to="/work/campaigns">Advertising</Sublink>
              <Sublink to="/work/editorial">Editorial</Sublink>
              <Sublink to="/work/motion">Motion</Sublink>
              <Sublink to="/work/clients-index">Index</Sublink>
            </Submenu>
          )}
          <MenuItem to="/agency">The Agency</MenuItem>
        </Menu>
        <Hamburger
          style={{
            opacity: hamburgerOpacity,
            "pointer-events": hamburgerPointer,
          }}
          onClick={() => animateScroll.scrollToTop({ duration: 600 })}
        >
          <Line />
          <Line />
          <Line />
        </Hamburger>
      </DesktopContainer>
    </>
  )
}
