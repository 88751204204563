import React, { Component } from "react"
import styled from "styled-components"
import { themeGet } from "styled-system"

import { Box } from "src/style"

import Logo from "../../../assets/svgs/logo.svg"
import Comma from "../../../assets/svgs/lil-comma.svg"
import ArrowNext from "../../../assets/svgs/arrow-next.svg"
import ArrowPrev from "../../../assets/svgs/arrow-prev.svg"
import Play from "../../../assets/svgs/play.svg"
import Pause from "../../../assets/svgs/pause.svg"

export const COMPONENTS = {
  Logo: <Logo />,
  Comma: <Comma />,
  Next: <ArrowNext />,
  Prev: <ArrowPrev />,
  Play: <Play />,
  Pause: <Pause />,
}

export const ICON_NAMES = Object.keys(COMPONENTS)

const DEFAULT_ICON_SIZE = 6 // 1em

const size = key => props =>
  props.theme.space[props[key] || props.size || DEFAULT_ICON_SIZE] ||
  props[key] ||
  props.size

const Container = styled(Box)`
  display: inline-block;
  position: relative;
  width: ${size("width")};
  height: ${size("height")};
  vertical-align: bottom;

  > svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: ${({ color, theme }) =>
      themeGet(`colors.${color}`, "inherit")({ theme })};
    stroke: ${({ color, theme }) =>
      themeGet(`colors.${color}`, "inherit")({ theme })} !important;
  }
`

export default class Icons extends Component {
  render() {
    const { name, ...rest } = this.props

    return <Container {...rest}>{COMPONENTS[name]}</Container>
  }
}
